var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var PN,yha,SN,TN,UN,VN,XN,YN,ZN,$N,cO,dO,eO,fO,gO,hO,iO,kO,lO,zha,Aha,Bha,Cha,Dha,Eha,nO,Fha,Gha,Hha,Iha,pO,qO,rO,sO,Jha,tO,uO,Kha,Lha,Mha,wO,yO,Nha,zO,AO,Oha,Pha,BO,CO,Qha,DO,EO,GO,HO,Rha,IO,Sha,KO,Tha,Uha,LO,NO,Vha,Wha,PO,QO,Xha,Yha,TO,Zha,UO,$ha,XO,YO,ZO,aia,bia,cia,$O,aP,bP,dia,eia,cP,fia,gia,hia,dP,fP,gP,iia,jia,kia,lia,mia,hP,iP,jP,nia,kP,oia,pia,qia,lP,ria,sia,tia,uia,mP,via,nP,wia,pP,qP,rP,sP,tP,xia,xP,yia,zia,yP,zP,Aia,AP,BP,Bia,DP,EP,FP,HP,IP,Cia,JP,Dia,Eia,Fia,KP,LP,MP,PP,
Gia,RP,SP,TP,UP,Hia,Iia,Jia,WP,XP,Kia,YP,Lia,Mia,Nia,Oia,bQ,Pia,Qia,cQ,dQ,eQ,Ria,fQ,gQ,hQ,Sia,iQ,Tia,Uia,jQ,kQ,lQ,nQ,oQ,Via,qQ,rQ,sQ,tQ,Wia,vQ,wQ,Xia,yQ,zQ,AQ,BQ,CQ,DQ,Yia,Zia,$ia,aja,bja,FQ,cja,dja,GQ,HQ,IQ,eja,JQ,fja,KQ,gja,hja,LQ,ija,jja,kja,lja,NQ,OQ,mja,nja,oja,RQ,pja,SQ,qja,TQ,rja,VQ,WQ,sja,XQ,YQ,ZQ,$Q,aR,tja,bR,uja,cR,vja,wja,dR,eR,fR,xja,yja,gR,zja,Aja,hR,Bja,Cja,Dja,Eja,kR,Fja,Gja,Hja,Ija,Jja,Kja,Lja,oR,pR,qR,rR,sR,Mja,tR,uR,vR,wR,Nja,Oja,Pja,Qja,xR,yR,zR,Rja,AR,BR,Sja,Tja,CR,Uja,Vja,DR,
Wja,FR,GR,Xja,HR,Yja,JR,KR,Zja,LR,MR,$ja,NR,aka,OR,bka,QR,RR,SR,TR,cka,UR,dka,VR,eka,WR,YR,$R,fka,gka,hka,bS,cS,ika,dS,jka,kka,lka,eS,mka,fS,nka,hS,iS,oka,pka,mS,nS,qka,oS,pS,qS,rS,rka,ska;PN=function(a,b){if(null!=a&&null!=a.Td)a=a.Td(a,b);else{var c=PN[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=PN._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("CoreSpec.checker",a);}return a};
yha=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.mM,a,$CLJS.pM,!1,$CLJS.nM,$CLJS.cj.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.QN=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Fm,a,$CLJS.gi(b)),b)};$CLJS.RN=function(a,b){return new $CLJS.hN(a,!1,b,null,null,null)};SN=function(a){return new $CLJS.ZM(a,null,null,null)};
TN=function(a,b,c,d){this.Nc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};UN=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return UN.l(0<b.length?new $CLJS.z(b.slice(0),0,null):null)};VN=function(a,b,c,d,e){this.kc=a;this.pc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};XN=function(a,b,c){return WN.l($CLJS.G([a,b,$CLJS.ag(!0),c]))};YN=function(a,b,c,d){this.zc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
ZN=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Jz,a)):$CLJS.Nb($CLJS.Jz,a)))throw Error($CLJS.VL("Not an IDeref: %s",$CLJS.G([a])));return new YN(a,null,null,null)};$N=function(a,b,c,d){this.X=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.aO=function(a){return new $N(a,null,null,null)};$CLJS.bO=function(a){return $CLJS.Nm.h($CLJS.iM,yha(function(b,c){return PN($CLJS.NM(b),c)},a))};
cO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.hN($CLJS.aO(d),!0,a,null,null,null);case "rest":return $CLJS.VM(d,a);default:return $CLJS.RN(b,a)}}else return $CLJS.RN(b,a)};
dO=function(a,b){return $CLJS.Mg($CLJS.nf($CLJS.RN($CLJS.RM(a),a),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=cO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(cO(n,g),
e($CLJS.zd(f)))}return null}},null,null)}($CLJS.uu(2,2,b))}()))};eO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
fO=function(a){return $CLJS.VM($CLJS.R.h(WN,$CLJS.Ve($CLJS.qg,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(eO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(eO,l),f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.bv.h(", ",$CLJS.rg.h($CLJS.C,a))].join(""))};gO=function(a){return $CLJS.XM(a,$CLJS.A,"Non-empty")};hO=function(a){return $CLJS.A(a)?$CLJS.R.h($CLJS.Pu,a):!0};iO=function(a){return"string"===typeof a?$CLJS.ki.h(Date.parse(a),NaN):null};
$CLJS.rM.prototype.Td=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.wM.prototype.Td=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Sb(function(f,g){return $CLJS.Iga(g,b,f)},function(f){return $CLJS.gM($CLJS.bM(d,f,new $CLJS.Yi(function(){var g=$CLJS.XL(f);return c.bc.g?c.bc.g(g):c.bc.call(null,g)}),null))},$CLJS.lf(c.options));return $CLJS.m(c.Ub)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.hM(g))return g;f=$CLJS.m($CLJS.pM.g(b))?g:f;f=c.Ub.g?c.Ub.g(f):c.Ub.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.EM.prototype.Td=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.pM.g(b))?c.sc:function(){return null},e=$CLJS.CM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.cj.g($CLJS.Lg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.Xf($CLJS.hM,l);return $CLJS.m(n)?$CLJS.gM(c.dc.j?c.dc.j(f,l,g):c.dc.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.jO={};kO={};lO={};$CLJS.h=TN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Nc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,this.Nc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.qN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Nc,b.Nc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.qN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new TN(this.Nc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.qN,b):$CLJS.O.call(null,$CLJS.qN,b))?new TN(c,this.F,this.m,null):new TN(this.Nc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.qN,this.Nc)],null),this.m))};$CLJS.h.O=function(a,b){return new TN(this.Nc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.sM($CLJS.kM(this,function(b){return $CLJS.Ie(a.Nc,b)},function(b){return new $CLJS.gf(null,a.Nc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.rha,this.Nc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};UN.l=function(a){return new TN($CLJS.si(a),null,null,null)};UN.v=0;
UN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=VN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.kc;case "error-symbol":return this.pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sN,this.kc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rN,this.pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sN,$CLJS.rN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.kc,b.kc)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.sN,null,$CLJS.rN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new VN(this.kc,this.pc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.sN,b):$CLJS.O.call(null,$CLJS.sN,b))?new VN(c,this.pc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.rN,b):$CLJS.O.call(null,$CLJS.rN,b))?new VN(this.kc,c,this.F,this.m,null):new VN(this.kc,this.pc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.sN,this.kc),new $CLJS.Je($CLJS.rN,this.pc)],null),this.m))};
$CLJS.h.O=function(a,b){return new VN(this.kc,this.pc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.xM($CLJS.MM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.tM,u,$CLJS.em,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.tM,l,$CLJS.em,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a.kc)}(),function(b){var c=a.pc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.kc))?$CLJS.Ui.g($CLJS.YL($CLJS.C($CLJS.C(a.kc)))):$CLJS.Uga;return new $CLJS.gf(null,c,new $CLJS.gf(null,b,null,1,null),2,null)})};
$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.Vga,$CLJS.qg.h($CLJS.sg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.YL(b)),$CLJS.OM(a)],null)},$CLJS.G([this.kc])),$CLJS.m(this.pc)?new $CLJS.S(null,1,5,$CLJS.T,[this.pc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
var WN=function WN(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return WN.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
WN.l=function(a){if(!$CLJS.A(a)||!($CLJS.Zf($CLJS.E(a))||$CLJS.$d(a)instanceof $CLJS.w))throw Error($CLJS.VL("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new VN($CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.WL(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Su)?$CLJS.ag(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.WL(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Su)?$CLJS.ag(!0):l,f],null);return $CLJS.nf(l,d($CLJS.zd(e)))}return null}},
null,null)}($CLJS.uu(2,2,a))}()),$CLJS.Zf($CLJS.E(a))?null:$CLJS.$d(a),null,null,null)};WN.v=0;WN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=YN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.zc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uN,this.zc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.zc,b.zc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.uN,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new YN(this.zc,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.uN,b):$CLJS.O.call(null,$CLJS.uN,b))?new YN(c,this.F,this.m,null):new YN(this.zc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.uN,this.zc)],null),this.m))};$CLJS.h.O=function(a,b){return new YN(this.zc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.yM($CLJS.MM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.em,$CLJS.r(this.zc)],null)],null))};
$CLJS.h.Fa=function(){if(this.zc instanceof $CLJS.ud){var a=$CLJS.fe(this.zc);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.Ek);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.Ui.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.gf(null,$CLJS.Kl,new $CLJS.gf(null,a,null,1,null),2,null)}else a=$CLJS.sha;return new $CLJS.gf(null,$CLJS.iha,new $CLJS.gf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=$N.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.em],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.em,null],null),null),b)?$CLJS.Fm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $N(this.X,this.F,$CLJS.Rf($CLJS.Fm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.em,b):$CLJS.O.call(null,$CLJS.em,b))?new $N(c,this.F,this.m,null):new $N(this.X,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.em,this.X)],null),this.m))};$CLJS.h.O=function(a,b){return new $N(this.X,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.yM($CLJS.MM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.tM,$CLJS.Cb,$CLJS.em,$CLJS.RM(null)],null),new $CLJS.k(null,1,[$CLJS.em,this.X],null)],null))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.jha,new $CLJS.gf(null,$CLJS.OM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
zha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);Aha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.mO=new $CLJS.N(null,"right-join","right-join",-56349359);Bha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);Cha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
Dha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);Eha=new $CLJS.N(null,"from","from",1815293044);nO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Fha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Gha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.oO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Iha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);pO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);qO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);rO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);sO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);Jha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
tO=new $CLJS.w(null,"stddev","stddev",775056588,null);uO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.vO=new $CLJS.N(null,"query","query",-1288509510);Kha=new $CLJS.N(null,"lat-field","lat-field",-830652957);Lha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);Mha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);wO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.xO=new $CLJS.N(null,"fields","fields",-1932066230);yO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Nha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);zO=new $CLJS.N(null,"unary","unary",-989314568);AO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Oha=new $CLJS.N(null,"lon-min","lon-min",-787291357);Pha=new $CLJS.N(null,"match","match",1220059550);
BO=new $CLJS.w(null,"count-where","count-where",2025939247,null);CO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Qha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);DO=new $CLJS.w(null,"sum","sum",1777518341,null);EO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.FO=new $CLJS.N("date","range","date/range",1647265776);GO=new $CLJS.w(null,"between","between",-1523336493,null);
HO=new $CLJS.N(null,"clause-form","clause-form",1820463737);Rha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);IO=new $CLJS.w(null,"field","field",338095027,null);Sha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.JO=new $CLJS.N(null,"filter","filter",-948537934);KO=new $CLJS.w(null,"not-null","not-null",313812992,null);Tha=new $CLJS.N(null,"template-tag","template-tag",310841038);
Uha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);LO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.MO=new $CLJS.N(null,"context","context",-830191113);NO=new $CLJS.w(null,"get-year","get-year",704520253,null);Vha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.OO=new $CLJS.N(null,"parameters","parameters",-1229919748);Wha=new $CLJS.N(null,"json-download","json-download",-971130133);
PO=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);QO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Xha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Yha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.RO=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.SO=new $CLJS.N("date","month-year","date/month-year",1948031290);
TO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Zha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);UO=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.VO=new $CLJS.N(null,"joins","joins",1033962699);$ha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.WO=new $CLJS.N(null,"source-field","source-field",933829534);XO=new $CLJS.w(null,"Field","Field",430385967,null);
YO=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);ZO=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);aia=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);bia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);cia=new $CLJS.N(null,"items","items",1031954938);$O=new $CLJS.N(null,"more","more",-2058821800);
aP=new $CLJS.N(null,"first-clause","first-clause",-20953491);bP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);dia=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);eia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);cP=new $CLJS.w(null,"contains","contains",-1977535957,null);fia=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
gia=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);hia=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);dP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.eP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);fP=new $CLJS.w(null,"is-null","is-null",-356519403,null);gP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);iia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
jia=new $CLJS.N(null,"required","required",1807647006);kia=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);lia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);mia=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);hP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);iP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);jP=new $CLJS.N("string","contains","string/contains",1602423827);
nia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);kP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);oia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);pia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);qia=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
lP=new $CLJS.w(null,"share","share",1051097594,null);ria=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);sia=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);tia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);uia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
mP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);via=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);nP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.oP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);wia=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);pP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
qP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);rP=new $CLJS.w(null,"metric","metric",2049329604,null);sP=new $CLJS.w(null,"concat","concat",-467652465,null);tP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.uP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);xia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.vP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.wP=new $CLJS.N(null,"alias","alias",-2039751630);
xP=new $CLJS.N("location","city","location/city",-1746973325);yia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);zia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);yP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);zP=new $CLJS.N("number","between","number/between",97700581);Aia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);AP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
BP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.CP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Bia=new $CLJS.N(null,"metric-id","metric-id",-686486942);DP=new $CLJS.w(null,"*","*",345799209,null);EP=new $CLJS.w(null,"+","+",-740910886,null);FP=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.GP=new $CLJS.N(null,"breakout","breakout",-732419050);HP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
IP=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Cia=new $CLJS.N(null,"question","question",-1411720117);JP=new $CLJS.w(null,"asc","asc",1997386096,null);Dia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);Eia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);Fia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
KP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);LP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);MP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.NP=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.OP=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);PP=new $CLJS.N("string","ends-with","string/ends-with",302681156);Gia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.QP=new $CLJS.w("s","Str","s/Str",907974338,null);RP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);SP=new $CLJS.w(null,"and","and",668631710,null);TP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);UP=new $CLJS.w(null,"round","round",-645002441,null);Hia=new $CLJS.N(null,"to","to",192099007);$CLJS.VP=new $CLJS.N("date","single","date/single",1554682003);Iia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
Jia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);WP=new $CLJS.w(null,"exp","exp",1378825265,null);XP=new $CLJS.w(null,"Filter","Filter",-424893332,null);Kia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);YP=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Lia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
Mia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.ZP=new $CLJS.N(null,"source-table","source-table",-225307692);Nia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.$P=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);Oia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.aQ=new $CLJS.N(null,"metric","metric",408798077);
bQ=new $CLJS.w(null,"floor","floor",-772394748,null);Pia=new $CLJS.N(null,"middleware","middleware",1462115504);Qia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);cQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);dQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);eQ=new $CLJS.w(null,"now","now",-9994004,null);Ria=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
fQ=new $CLJS.w(null,"avg","avg",1837937727,null);gQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);hQ=new $CLJS.N(null,"max-results","max-results",-32858165);Sia=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);iQ=new $CLJS.w(null,"case","case",-1510733573,null);Tia=new $CLJS.w("js","Date","js/Date",946858373,null);Uia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
jQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);kQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);lQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.mQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);nQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);oQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);Via=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.pQ=new $CLJS.w("s","pred","s/pred",-727014287,null);qQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);rQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);sQ=new $CLJS.w(null,"abs","abs",1394505050,null);tQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Wia=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.uQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
vQ=new $CLJS.N(null,"date","date",-1463434462);wQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.xQ=new $CLJS.N(null,"left-join","left-join",-672831855);Xia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);yQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);zQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);AQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
BQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);CQ=new $CLJS.w(null,"or","or",1876275696,null);DQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.EQ=new $CLJS.N(null,"field","field",-1302436500);Yia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Zia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);$ia=new $CLJS.N(null,"constraints","constraints",422775616);
aja=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);bja=new $CLJS.N(null,"csv-download","csv-download",2141432084);FQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);cja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);dja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
GQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);HQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);IQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);eja=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);JQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
fja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);KQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);gja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);hja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);LQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
ija=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);jja=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);kja=new $CLJS.N(null,"display_name","display_name",-1494335013);lja=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.MQ=new $CLJS.N(null,"database","database",1849087575);NQ=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
OQ=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);mja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.PQ=new $CLJS.N(null,"expressions","expressions",255689909);nja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);oja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.QQ=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
RQ=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);pja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);SQ=new $CLJS.w(null,"get-day","get-day",1768100384,null);qja=new $CLJS.w(null,"Fields","Fields",430617156,null);TQ=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.UQ=new $CLJS.N(null,"native","native",-613060878);rja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
VQ=new $CLJS.N(null,"page","page",849072397);WQ=new $CLJS.w(null,"length","length",-2065447907,null);sja=new $CLJS.N(null,"dashboard","dashboard",-631747508);XQ=new $CLJS.w(null,"get-week","get-week",752472178,null);YQ=new $CLJS.w(null,"get-month","get-month",1271156796,null);ZQ=new $CLJS.w(null,"dimension","dimension",-2111181571,null);$Q=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
aR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);tja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);bR=new $CLJS.w(null,"substring","substring",-1513569493,null);uja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);cR=new $CLJS.N(null,"internal","internal",-854870097);vja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
wja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);dR=new $CLJS.N(null,"declared","declared",92336021);eR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);fR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);xja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);yja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
gR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);zja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Aja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);hR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.iR=new $CLJS.N(null,"template-tags","template-tags",1853115685);Bja=new $CLJS.N(null,"public-question","public-question",629369976);
Cja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.jR=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);Dja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Eja=new $CLJS.N(null,"binary","binary",-1802232288);kR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.lR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.mR=new $CLJS.N(null,"settings","settings",1556144875);Gja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.nR=new $CLJS.N(null,"inner-join","inner-join",659431740);Hja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);Ija=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);Jja=new $CLJS.w("s","eq","s/eq",1021992833,null);
Kja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Lja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);oR=new $CLJS.N(null,"amount","amount",364489504);pR=new $CLJS.w(null,"percentile","percentile",1039342775,null);qR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);rR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);sR=new $CLJS.w(null,"trim","trim",-1880116002,null);
Mja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);tR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);uR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);vR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);wR=new $CLJS.N("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
Oja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Qja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);xR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);yR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);zR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
Rja=new $CLJS.N(null,"lat-min","lat-min",1630784161);AR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);BR=new $CLJS.w(null,"inside","inside",-681932758,null);Sja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);Tja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);CR=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
Uja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Vja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);DR=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.ER=new $CLJS.N(null,"category","category",-593092832);Wja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);FR=new $CLJS.w(null,"upper","upper",1886775433,null);GR=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);
Xja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);HR=new $CLJS.N(null,"sugar","sugar",-73788488);Yja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.IR=new $CLJS.N(null,"full-join","full-join",1305476385);JR=new $CLJS.w(null,"power","power",702679448,null);KR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Zja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
LR=new $CLJS.w(null,"median","median",-2084869638,null);MR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);$ja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);NR=new $CLJS.N(null,"y","y",-1757859776);aka=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);OR=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);
bka=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.PR=new $CLJS.N(null,"binning","binning",1709835866);QR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);RR=new $CLJS.N(null,"b","b",1482224470);SR=new $CLJS.N(null,"a","a",-2123407586);TR=new $CLJS.w(null,"replace","replace",853943757,null);cka=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
UR=new $CLJS.w(null,"segment","segment",675610331,null);dka=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);VR=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);eka=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);WR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);YR=new $CLJS.N("string","starts-with","string/starts-with",1266861170);
$CLJS.ZR=new $CLJS.N(null,"order-by","order-by",1527318070);$R=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);fka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);gka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);hka=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.aS=new $CLJS.N(null,"condition","condition",1668437652);
bS=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);cS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);ika=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);dS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);jka=new $CLJS.N(null,"card-name","card-name",-2035606807);
kka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);lka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);eS=new $CLJS.w(null,"log","log",45015523,null);mka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);fS=new $CLJS.w(null,"time","time",-1268547887,null);
nka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.gS=new $CLJS.N(null,"database_type","database_type",-54700895);hS=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);iS=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.jS=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.kS=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.lS=new $CLJS.N("date","all-options","date/all-options",549325958);oka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);pka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);mS=new $CLJS.N("location","state","location/state",-114378652);nS=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);qka=new $CLJS.N(null,"lon-field","lon-field",517872067);
oS=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);pS=new $CLJS.N(null,"numeric","numeric",-1495594714);qS=new $CLJS.w(null,"variable","variable",1359185035,null);rS=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.sS=new $CLJS.N(null,"limit","limit",-1355822363);rka=new $CLJS.w("s","enum","s/enum",-975416934,null);ska=new $CLJS.N(null,"pulse","pulse",-244494476);var tS,uS,vS,tka,uka,vka,wka,yS,zS,xka,yka,zka,AS,Aka,BS,CS,DS,Bka,ES,Cka,FS,GS,Dka,Eka,KS,Fka,NS,PS,QS,RS,SS,TS,WS,YS,$S,aT,Gka,bT,cT,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,wT,xT,US,zT,AT,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,OT,PT,OS,ST,TT,UT,VT,WT,XT,YT,ZT,$T,aU,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,mU,nU,Hka,oU,pU,ZS,qU,rU,Ika,Jka,sU,yT,QT,uU,vU,wU,xU,yU,zU,AU,BU,CU,DU,EU,FU,GU,HU,IU,JU,KU,LU,Kka,NU,OU,QU,Lka,Mka,RU,Nka,Oka,Pka,Qka,Rka,TU,VU,WU,YU,Ska,Tka,Uka,ZU,$U,Vka,SU,aV,bV,
cV,Wka,Xka,Yka,Zka,$ka,ala,bla,cla,dla;tS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.Zk,null,$CLJS.Pj,null,$CLJS.nk,null,$CLJS.kl,null,$CLJS.cm,null,$CLJS.vl,null,$CLJS.Jj,null,$CLJS.tl,null,$CLJS.Uj,null,$CLJS.hk,null],null),null);uS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.vz,null,$CLJS.rz,null,$CLJS.Pj,null,$CLJS.bk,null,$CLJS.sz,null,$CLJS.Sk,null,$CLJS.tz,null],null),null);vS=$CLJS.xz.h(tS,uS);tka=$CLJS.VM($CLJS.R.h(UN,tS),"date-bucketing-unit");
uka=$CLJS.VM($CLJS.R.h(UN,uS),"time-bucketing-unit");$CLJS.wS=$CLJS.VM($CLJS.R.h(UN,vS),"datetime-bucketing-unit");$CLJS.xS=$CLJS.VM($CLJS.R.h(UN,$CLJS.UL.tz.names()),"timezone-id");vka=$CLJS.VM($CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.ZK,null,$CLJS.nk,null,$CLJS.bk,null,$CLJS.cm,null,$CLJS.vl,null,$CLJS.fJ,null,$CLJS.II,null,$CLJS.UK,null,$CLJS.XI,null,$CLJS.hk,null,$CLJS.Sk,null],null),null)),"temporal-extract-units");
wka=$CLJS.VM($CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.rz,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.tz,null],null),null)),"datetime-diff-units");yS=$CLJS.VM($CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.IJ,null,$CLJS.vI,null,$CLJS.qJ,null],null),null)),"extract-week-modes");
zS=$CLJS.VM($CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.Pj,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.tz,null],null),null)),"relative-datetime-unit");xka=$CLJS.XM($CLJS.KN,iO,"valid ISO-8601 datetime string literal");yka=$CLJS.XM($CLJS.KN,iO,"valid ISO-8601 datetime string literal");zka=$CLJS.XM($CLJS.KN,iO,"valid ISO-8601 time string literal");
AS=dO($CLJS.rJ,$CLJS.G(["n",$CLJS.JN($CLJS.G([$CLJS.RM($CLJS.mB),$CLJS.FN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,zS],null)]));Aka=dO($CLJS.iJ,$CLJS.G(["n",$CLJS.FN,"unit",zS]));BS=WN.l($CLJS.G([function(a){return $CLJS.Gb(eO($CLJS.FI,a))},dO($CLJS.FI,$CLJS.G(["t",Date,"unit",$CLJS.wS])),function(a){return $CLJS.Zd(a)instanceof Date},dO($CLJS.FI,$CLJS.G(["date",Date,"unit",tka])),$CLJS.Su,dO($CLJS.FI,$CLJS.G(["datetime",Date,"unit",$CLJS.wS]))]));
CS=dO($CLJS.YD,$CLJS.G(["time",Date,"unit",uka]));DS=WN.l($CLJS.G([$CLJS.bg(eO,$CLJS.FI),BS,iO,yka,iO,xka,$CLJS.Su,$CLJS.JN($CLJS.G([Date]))]));Bka=WN.l($CLJS.G([$CLJS.bg(eO,$CLJS.YD),CS,iO,zka,$CLJS.Su,$CLJS.JN($CLJS.G([Date]))]));ES=$CLJS.JN($CLJS.G([Bka,DS]));
fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof BS?new $CLJS.ud(function(){return BS},$CLJS.K(aia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,cR,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RQ,"metabase/mbql/schema.cljc",69,$CLJS.FI,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(BS)?BS.J:null])):null));return $CLJS.m(a)?a:RQ}(),BS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof AS?new $CLJS.ud(function(){return AS},$CLJS.K(oka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,
$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",29,$CLJS.rJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rJ,$CLJS.tC,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uQ,$CLJS.X(Jja,$CLJS.mB),DQ)),$CLJS.Wj,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,RP))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(AS)?AS.J:null])):null));return $CLJS.m(a)?
a:qQ}(),AS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof CS?new $CLJS.ud(function(){return CS},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,cR,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fS,"metabase/mbql/schema.cljc",27,$CLJS.YD,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.YD,$CLJS.YD,$CLJS.X($CLJS.Gl,Tia),$CLJS.Wj,$CLJS.X($CLJS.Gl,xia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(CS)?CS.J:null])):null));return $CLJS.m(a)?a:fS}(),CS],null)]));Cka=$CLJS.Pf([SN($CLJS.gS),$CLJS.aO($CLJS.KN),SN($CLJS.tJ),$CLJS.aO($CLJS.LN),SN($CLJS.CP),$CLJS.aO($CLJS.MN),SN($CLJS.Wj),$CLJS.aO($CLJS.wS),SN($CLJS.V),$CLJS.aO($CLJS.KN),$CLJS.GN,$CLJS.BN]);
FS=dO($CLJS.rl,$CLJS.G(["value",$CLJS.BN,"type-info",$CLJS.aO(Cka)]));GS=dO($CLJS.zG,$CLJS.G(["expression-name",$CLJS.KN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.TM($CLJS.oe,"map")],null)]));Dka=UN.l($CLJS.G([$CLJS.CI,$CLJS.TK,$CLJS.Pj]));
Eka=function(a){return $CLJS.XM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.WK);c=$CLJS.M.h(c,$CLJS.CI);return $CLJS.F.h(b,$CLJS.CI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.XM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.WK);c=$CLJS.M.h(c,$CLJS.TK);return $CLJS.F.h(b,$CLJS.TK)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Pf([$CLJS.WK,Dka,SN($CLJS.CI),$CLJS.NN,SN($CLJS.TK),$CLJS.XM($CLJS.EN,$CLJS.$f($CLJS.ef),
"bin width must be \x3e\x3d 0."),$CLJS.GN,$CLJS.BN])));$CLJS.HS=function HS(a){switch(arguments.length){case 1:return HS.g(arguments[0]);case 2:return HS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.HS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.CG);b=$CLJS.M.h(b,$CLJS.QQ);return $CLJS.HS.h(a,b)};
$CLJS.HS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.OH(a,$CLJS.OI)?tS:$CLJS.OH(a,$CLJS.XK)?uS:$CLJS.OH(a,$CLJS.SJ)?vS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.HS.v=2;
var IS=$CLJS.XM($CLJS.XM($CLJS.Pf([SN($CLJS.CG),$CLJS.aO($CLJS.LN),SN($CLJS.WO),$CLJS.aO($CLJS.JN($CLJS.G([$CLJS.NN,$CLJS.KN]))),SN($CLJS.QQ),$CLJS.aO($CLJS.wS),SN($CLJS.mQ),$CLJS.aO($CLJS.KN),SN($CLJS.PR),$CLJS.aO(Eka),$CLJS.GN,$CLJS.BN]),$CLJS.HS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.$f($CLJS.WK),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),JS=function(a){return $CLJS.XM(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.CG);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(dO($CLJS.EQ,$CLJS.G(["id-or-name",$CLJS.JN($CLJS.G([$CLJS.NN,$CLJS.KN])),"options",$CLJS.aO(ZN(new $CLJS.ud(function(){return IS},nka,$CLJS.Sh([$CLJS.qm,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[!0,$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Mha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(IS)?IS.J:null]))))])));$CLJS.XM(JS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
KS=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof GS?new $CLJS.ud(function(){return GS},$CLJS.K(gia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.RO,"metabase/mbql/schema.cljc",60,$CLJS.zG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.zG,$CLJS.kS,$CLJS.X($CLJS.Gl,OR),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,$CLJS.X($CLJS.pQ,$CLJS.fk,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(GS)?GS.J:null])):null));return $CLJS.m(a)?a:$CLJS.RO}(),GS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof JS?
new $CLJS.ud(function(){return JS},$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.bl,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],["0.39.0",$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.EQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(JS)?JS.J:null])):null));return $CLJS.m(a)?
a:IO}(),JS],null)]));$CLJS.LS=ZN(new $CLJS.ud(function(){return KS},Cja,$CLJS.Sh([$CLJS.qm,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[!0,$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(KS)?KS.J:null])));
Fka=dO($CLJS.NP,$CLJS.G(["aggregation-clause-index",$CLJS.FN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.TM($CLJS.oe,"map")],null)]));$CLJS.MS=XN($CLJS.bg(eO,$CLJS.NP),Fka,$CLJS.LS);NS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.Qu,null,$CLJS.GK,null,$CLJS.aI,null,$CLJS.VK,null,$CLJS.oI,null,$CLJS.QJ,null,$CLJS.ZI,null,$CLJS.$u,null,$CLJS.BK,null,$CLJS.OJ,null,$CLJS.YK,null],null),null);
PS=WN.l($CLJS.G([$CLJS.Jb,$CLJS.CN,$CLJS.bg(eO,NS),ZN(new $CLJS.ud(function(){return OS},cS,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(OS)?OS.J:null]))),$CLJS.bg(eO,$CLJS.rl),FS,$CLJS.Su,$CLJS.LS]));
QS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.jI,null,$CLJS.SK,null,$CLJS.om,null,$CLJS.oK,null,$CLJS.gK,null,$CLJS.oJ,null,$CLJS.wu,null,$CLJS.ZH,null,$CLJS.wK,null,$CLJS.cK,null,$CLJS.QJ,null,$CLJS.PK,null,$CLJS.jK,null,$CLJS.VJ,null,$CLJS.mI,null,$CLJS.DI,null,$CLJS.nm,null,$CLJS.tI,null,$CLJS.ZI,null,$CLJS.YH,null,$CLJS.iD,null,$CLJS.EI,null,$CLJS.aK,null,$CLJS.hJ,null,$CLJS.RK,null,$CLJS.GJ,null],null),null);
RS=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.vm,null,$CLJS.lm,null,$CLJS.Ck,null,$CLJS.Uk,null,$CLJS.um,null,$CLJS.CK,null,$CLJS.Lj,null,$CLJS.im,null,$CLJS.jm,null],null),null);SS=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.rk,null,$CLJS.zK,null,$CLJS.eK,null,$CLJS.MJ,null,$CLJS.NK,null,$CLJS.QK,null,$CLJS.MI,null,$CLJS.mJ,null,$CLJS.oO,null,$CLJS.QI,null,$CLJS.uK,null,$CLJS.YJ,null,$CLJS.$l,null,$CLJS.kD,null,$CLJS.CJ,null,$CLJS.aQ,null,$CLJS.sK,null],null),null);
TS=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DJ,null,$CLJS.uI,null,$CLJS.LK,null,$CLJS.nm,null,$CLJS.dK,null],null),null);
WS=WN.l($CLJS.G([$CLJS.Eb,$CLJS.EN,$CLJS.bg(eO,QS),ZN(new $CLJS.ud(function(){return US},uR,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(US)?US.J:null]))),$CLJS.bg(eO,SS),ZN(new $CLJS.ud(function(){return $CLJS.VS},yO,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.VS)?$CLJS.VS.J:null]))),$CLJS.bg(eO,$CLJS.rl),FS,$CLJS.Su,$CLJS.LS]));
YS=WN.l($CLJS.G([$CLJS.bg(eO,SS),ZN(new $CLJS.ud(function(){return $CLJS.VS},yO,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.VS)?$CLJS.VS.J:null]))),$CLJS.bg(eO,$CLJS.rl),FS,$CLJS.bg(eO,TS),ZN(new $CLJS.ud(function(){return $CLJS.XS},
BP,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.XS)?$CLJS.XS.J:null]))),$CLJS.Su,$CLJS.JN($CLJS.G([DS,$CLJS.LS]))]));
$S=WN.l($CLJS.G([$CLJS.Eb,$CLJS.EN,$CLJS.ve,$CLJS.DN,$CLJS.bg(eO,RS),ZN(new $CLJS.ud(function(){return ZS},Zha,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hia,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m(ZS)?ZS.J:null]))),$CLJS.bg(eO,QS),ZN(new $CLJS.ud(function(){return US},
uR,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(US)?US.J:null]))),$CLJS.bg(eO,TS),ZN(new $CLJS.ud(function(){return $CLJS.XS},BP,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],
[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.XS)?$CLJS.XS.J:null]))),$CLJS.Jb,$CLJS.CN,$CLJS.bg(eO,NS),ZN(new $CLJS.ud(function(){return OS},cS,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(OS)?OS.J:null]))),$CLJS.bg(eO,$CLJS.rl),FS,$CLJS.Su,$CLJS.LS]));aT=XN($CLJS.bg(eO,$CLJS.iJ),Aka,WS);Gka=XN($CLJS.Eb,$CLJS.NN,WS);bT=dO($CLJS.QJ,$CLJS.G(["a",$S,"b",$S,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,$S],null)]));cT=dO($CLJS.aI,$CLJS.G(["s",PS,"start",Gka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,WS],null)]));dT=dO($CLJS.iD,$CLJS.G(["s",PS]));eT=dO($CLJS.BK,$CLJS.G(["s",PS]));
fT=dO($CLJS.YK,$CLJS.G(["s",PS]));gT=dO($CLJS.GK,$CLJS.G(["s",PS]));hT=dO($CLJS.Qu,$CLJS.G(["s",PS]));iT=dO($CLJS.$u,$CLJS.G(["s",PS]));jT=dO($CLJS.VK,$CLJS.G(["s",PS,"match",$CLJS.CN,"replacement",$CLJS.CN]));kT=dO($CLJS.oI,$CLJS.G(["a",PS,"b",PS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,PS],null)]));lT=dO($CLJS.OJ,$CLJS.G(["s",PS,"pattern",$CLJS.CN]));mT=dO($CLJS.nm,$CLJS.G(["x",aT,"y",aT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,aT],null)]));
nT=dO($CLJS.wu,$CLJS.G(["x",WS,"y",aT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,aT],null)]));oT=dO($CLJS.PK,$CLJS.G(["x",WS,"y",WS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,WS],null)]));pT=dO($CLJS.om,$CLJS.G(["x",WS,"y",WS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,WS],null)]));qT=dO($CLJS.GJ,$CLJS.G(["x",WS]));rT=dO($CLJS.jI,$CLJS.G(["x",WS]));sT=dO($CLJS.VJ,$CLJS.G(["x",WS]));tT=dO($CLJS.tI,$CLJS.G(["x",WS]));uT=dO($CLJS.mI,$CLJS.G(["x",WS,"y",WS]));vT=dO($CLJS.oJ,$CLJS.G(["x",WS]));
wT=dO($CLJS.ZH,$CLJS.G(["x",WS]));xT=dO($CLJS.RK,$CLJS.G(["x",WS]));US=ZN(new $CLJS.ud(function(){return yT},dia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Wja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(yT)?yT.J:null])));
zT=dO($CLJS.hJ,$CLJS.G(["datetime-x",YS,"datetime-y",YS,"unit",wka]));AT=dO($CLJS.oK,$CLJS.G(["datetime",YS,"unit",vka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,yS],null)]));BT=dO($CLJS.EI,$CLJS.G(["date",YS]));CT=dO($CLJS.SK,$CLJS.G(["date",YS]));DT=dO($CLJS.YH,$CLJS.G(["date",YS]));ET=dO($CLJS.cK,$CLJS.G(["date",YS,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,yS],null)]));FT=dO($CLJS.aK,$CLJS.G(["date",YS]));GT=dO($CLJS.jK,$CLJS.G(["date",YS]));HT=dO($CLJS.wK,$CLJS.G(["datetime",YS]));
IT=dO($CLJS.gK,$CLJS.G(["datetime",YS]));JT=dO($CLJS.DI,$CLJS.G(["datetime",YS]));KT=dO($CLJS.uI,$CLJS.G(["datetime",YS,"to",$CLJS.xS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.xS],null)]));LT=$CLJS.VM($CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.rz,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.sz,null,$CLJS.tz,null],null),null)),"arithmetic-datetime-unit");MT=dO($CLJS.dK,$CLJS.G(["datetime",YS,"amount",WS,"unit",LT]));
NT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return dO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.DJ);OT=dO($CLJS.LK,$CLJS.G(["datetime",YS,"amount",WS,"unit",LT]));
PT=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K(KQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EP,"metabase/mbql/schema.cljc",51,$CLJS.nm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nm,$CLJS.IG,$CLJS.X($CLJS.Gl,NQ),NR,$CLJS.X($CLJS.Gl,NQ),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,NQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:EP}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},
$CLJS.K(Ria,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[iP,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FQ,"metabase/mbql/schema.cljc",67,$CLJS.dK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.dK,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS),oR,$CLJS.X($CLJS.Gl,
rR),$CLJS.Wj,$CLJS.X($CLJS.Gl,AR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?a:FQ}(),MT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof OT?new $CLJS.ud(function(){return OT},$CLJS.K(zha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,
$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[iP,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",72,$CLJS.LK,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.LK,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS),oR,$CLJS.X($CLJS.Gl,rR),$CLJS.Wj,$CLJS.X($CLJS.Gl,AR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(OT)?OT.J:null])):null));return $CLJS.m(a)?
a:nS}(),OT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},$CLJS.K(Tja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.uI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",71,$CLJS.uI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.uI,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS),Hia,$CLJS.X($CLJS.Gl,QR),Eha,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,QR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:YO}(),KT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof NT?new $CLJS.ud(function(){return NT},
$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.DJ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",45,$CLJS.DJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.DJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(NT)?NT.J:null])):null));return $CLJS.m(a)?a:eQ}(),NT],null)]));$CLJS.XS=ZN(new $CLJS.ud(function(){return PT},Dia,$CLJS.Sh([$CLJS.qm,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[!0,$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Iia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(PT)?PT.J:null])));
OS=ZN(new $CLJS.ud(function(){return QT},Zja,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Uia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m(QT)?QT.J:null])));
ST=dO($CLJS.Lj,$CLJS.G(["first-clause",ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null]))),"second-clause",ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,
$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null])))],null)]));
TT=dO($CLJS.Ck,$CLJS.G(["first-clause",ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null]))),"second-clause",ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,
$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null])))],null)]));
UT=dO($CLJS.Uk,$CLJS.G(["clause",ZN(new $CLJS.ud(function(){return $CLJS.RT},BQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.RT)?$CLJS.RT.J:null])))]));VT=XN($CLJS.bg(eO,$CLJS.rJ),AS,$CLJS.LS);
WT=$CLJS.aO($CLJS.JN($CLJS.G([$CLJS.DN,$CLJS.EN,$CLJS.CN,ES,VT,$S,FS])));XT=XN($CLJS.bg(eO,$CLJS.rl),FS,$CLJS.JN($CLJS.G([$CLJS.EN,$CLJS.CN,ES,$S,VT])));YT=dO($CLJS.im,$CLJS.G(["field",WT,"value-or-field",WT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,WT],null)]));ZT=dO($CLJS.CK,$CLJS.G(["field",WT,"value-or-field",WT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,WT],null)]));$T=dO($CLJS.jm,$CLJS.G(["field",XT,"value-or-field",XT]));
aU=dO($CLJS.lm,$CLJS.G(["field",XT,"value-or-field",XT]));bU=dO($CLJS.vm,$CLJS.G(["field",XT,"value-or-field",XT]));cU=dO($CLJS.um,$CLJS.G(["field",XT,"value-or-field",XT]));dU=dO($CLJS.JI,$CLJS.G(["field",XT,"min",XT,"max",XT]));eU=dO($CLJS.UI,$CLJS.G(["lat-field",XT,"lon-field",XT,"lat-max",XT,"lon-min",XT,"lat-min",XT,"lon-max",XT]));fU=dO($CLJS.xI,$CLJS.G(["field",$CLJS.LS]));gU=dO($CLJS.nJ,$CLJS.G(["field",$CLJS.LS]));hU=dO($CLJS.KI,$CLJS.G(["field",$CLJS.LS]));
iU=dO($CLJS.$I,$CLJS.G(["field",$CLJS.LS]));jU=$CLJS.Pf([SN($CLJS.$J),$CLJS.DN]);kU=dO($CLJS.uJ,$CLJS.G(["field",PS,"string-or-field",PS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,jU],null)]));lU=dO($CLJS.mK,$CLJS.G(["field",PS,"string-or-field",PS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,jU],null)]));mU=dO($CLJS.KJ,$CLJS.G(["field",PS,"string-or-field",PS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,jU],null)]));
nU=dO($CLJS.vJ,$CLJS.G(["field",PS,"string-or-field",PS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,jU],null)]));Hka=$CLJS.Pf([SN($CLJS.xK),$CLJS.DN]);oU=dO($CLJS.qK,$CLJS.G(["field",$CLJS.LS,"n",$CLJS.JN($CLJS.G([$CLJS.FN,UN.l($CLJS.G([$CLJS.mB,$CLJS.oD,$CLJS.RI]))])),"unit",zS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Hka],null)]));pU=dO($CLJS.nI,$CLJS.G(["segment-id",$CLJS.JN($CLJS.G([$CLJS.NN,$CLJS.KN]))]));
ZS=ZN(new $CLJS.ud(function(){return qU},Fia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(qU)?qU.J:null])));
qU=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(KR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,aP,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),wQ,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),gP,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:SP}(),ST],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==
typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K(zQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",14,$CLJS.Ck,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ck,aP,$CLJS.X($CLJS.Gl,
$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),wQ,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),gP,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:CQ}(),TT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(aR,new $CLJS.k(null,
1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$L,"metabase/mbql/schema.cljc",15,$CLJS.Uk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uk,$CLJS.jS,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(UT)?UT.J:
null])):null));return $CLJS.m(a)?a:$CLJS.$L}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LP,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.jm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:LP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(dS,new $CLJS.k(null,1,
[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",14,$CLJS.vm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(bU)?bU.J:null])):
null));return $CLJS.m(a)?a:KP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MP,"metabase/mbql/schema.cljc",13,$CLJS.lm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.lm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:MP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(WR,new $CLJS.k(null,1,
[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",14,$CLJS.um,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.um,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(cU)?cU.J:null])):
null));return $CLJS.m(a)?a:TP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(HQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.pN,"metabase/mbql/schema.cljc",13,$CLJS.im,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.im,$CLJS.EQ,$CLJS.X($CLJS.Gl,hS),yR,$CLJS.X($CLJS.Gl,hS),eR,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,hS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?a:$CLJS.pN}(),YT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},
$CLJS.K(EO,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",14,$CLJS.CK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.CK,$CLJS.EQ,$CLJS.X($CLJS.Gl,hS),yR,$CLJS.X($CLJS.Gl,hS),eR,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,hS))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:QO}(),ZT],null)]));
rU=WN.l($CLJS.G([$CLJS.bg(eO,TS),$CLJS.XS,$CLJS.bg(eO,QS),US,$CLJS.bg(eO,NS),OS,$CLJS.bg(eO,RS),ZS,$CLJS.Su,fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(KR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,aP,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),wQ,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),gP,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:SP}(),ST],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K(zQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CQ,"metabase/mbql/schema.cljc",14,$CLJS.Ck,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ck,aP,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),wQ,$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))),gP,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:CQ}(),TT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==
typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(aR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$L,"metabase/mbql/schema.cljc",15,$CLJS.Uk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uk,$CLJS.jS,
$CLJS.X($CLJS.Gl,$CLJS.X(UO,$CLJS.X($CLJS.Kl,XP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:$CLJS.$L}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(HQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,
HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.pN,"metabase/mbql/schema.cljc",13,$CLJS.im,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.im,$CLJS.EQ,$CLJS.X($CLJS.Gl,hS),yR,$CLJS.X($CLJS.Gl,hS),eR,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,hS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?a:$CLJS.pN}(),YT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(EO,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",
14,$CLJS.CK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.CK,$CLJS.EQ,$CLJS.X($CLJS.Gl,hS),yR,$CLJS.X($CLJS.Gl,hS),eR,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,hS))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:QO}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(IQ,new $CLJS.k(null,
1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.jm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m($T)?$T.J:null])):null));
return $CLJS.m(a)?a:LP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MP,"metabase/mbql/schema.cljc",13,$CLJS.lm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.lm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:MP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(dS,new $CLJS.k(null,1,
[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",14,$CLJS.vm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vm,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(bU)?bU.J:null])):
null));return $CLJS.m(a)?a:KP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(WR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TP,"metabase/mbql/schema.cljc",14,$CLJS.um,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.um,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),yR,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:TP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(jja,new $CLJS.k(null,
1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",19,$CLJS.JI,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.JI,$CLJS.EQ,$CLJS.X($CLJS.Gl,bP),$CLJS.rk,$CLJS.X($CLJS.Gl,bP),$CLJS.$l,$CLJS.X($CLJS.Gl,bP)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:GO}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(mja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CR,"metabase/mbql/schema.cljc",23,$CLJS.uJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.uJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,xR),PO,$CLJS.X($CLJS.Gl,xR),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,$Q))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:CR}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},
$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",21,$CLJS.mK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.mK,$CLJS.EQ,$CLJS.X($CLJS.Gl,xR),PO,$CLJS.X($CLJS.Gl,xR),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,$Q))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(lU)?lU.J:null])):null));return $CLJS.m(a)?a:TO}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",20,$CLJS.KJ,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,xR),PO,$CLJS.X($CLJS.Gl,xR),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,$Q))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(mU)?mU.J:null])):null));return $CLJS.m(a)?a:cP}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==
typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(lka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",36,$CLJS.vJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.vJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,xR),PO,$CLJS.X($CLJS.Gl,xR),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,$Q))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:nO}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(Pja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),
$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BR,"metabase/mbql/schema.cljc",26,$CLJS.UI,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.UI,Kha,$CLJS.X($CLJS.Gl,bP),qka,$CLJS.X($CLJS.Gl,bP),Yja,$CLJS.X($CLJS.Gl,bP),Oha,$CLJS.X($CLJS.Gl,bP),Rja,$CLJS.X($CLJS.Gl,bP),Jha,$CLJS.X($CLJS.Gl,bP)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:BR}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(wia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",28,$CLJS.KI,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KI,$CLJS.EQ,$CLJS.X($CLJS.Gl,XO)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:nQ}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&
"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",29,$CLJS.$I,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$I,$CLJS.EQ,$CLJS.X($CLJS.Gl,XO)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:TQ}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(fka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",27,$CLJS.xI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xI,$CLJS.EQ,$CLJS.X($CLJS.Gl,XO)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:fP}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&
"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(Jia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",28,$CLJS.nJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,XO)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:KO}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",33,$CLJS.qK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.qK,$CLJS.EQ,$CLJS.X($CLJS.Gl,XO),$CLJS.tC,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uQ,DQ,$CLJS.X(rka,$CLJS.mB,$CLJS.oD,$CLJS.RI))),$CLJS.Wj,$CLJS.X($CLJS.Gl,RP),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,pja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(oU)?oU.J:null])):null));
return $CLJS.m(a)?a:kR}(),oU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof pU?new $CLJS.ud(function(){return pU},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UR,"metabase/mbql/schema.cljc",27,$CLJS.nI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nI,Sha,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uQ,ZO,OR))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(pU)?pU.J:null])):null));return $CLJS.m(a)?a:UR}(),pU],null)]))]));
$CLJS.RT=ZN(new $CLJS.ud(function(){return rU},hka,$CLJS.Sh([$CLJS.qm,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[!0,$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kia,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(rU)?rU.J:null])));
Ika=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.RN($CLJS.RT,"pred"),$CLJS.RN($S,"expr")],null)],null);Jka=$CLJS.Pf([SN($CLJS.Pj),$S]);sU=dO($CLJS.ZI,$CLJS.G(["clauses",Ika,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Jka],null)]));
yT=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K(KQ,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EP,"metabase/mbql/schema.cljc",51,$CLJS.nm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nm,$CLJS.IG,$CLJS.X($CLJS.Gl,NQ),NR,$CLJS.X($CLJS.Gl,NQ),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,NQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:EP}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},
$CLJS.K(fja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",51,$CLJS.wu,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.wu,$CLJS.IG,$CLJS.X($CLJS.Gl,rR),NR,
$CLJS.X($CLJS.Gl,NQ),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,NQ))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:FP}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO?new $CLJS.ud(function(){return oT},$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,dQ,$CLJS.Bk,$CLJS.Yk,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Tu,"metabase/mbql/schema.cljc",$CLJS.PK,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PK,$CLJS.IG,$CLJS.X($CLJS.Gl,rR),NR,$CLJS.X($CLJS.Gl,rR),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,rR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Tu}(),oT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DP,"metabase/mbql/schema.cljc",51,$CLJS.om,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.om,$CLJS.IG,$CLJS.X($CLJS.Gl,rR),NR,$CLJS.X($CLJS.Gl,rR),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,rR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:DP}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof bT?new $CLJS.ud(function(){return bT},
$CLJS.K(gR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",58,$CLJS.QJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QJ,SR,$CLJS.X($CLJS.Gl,qO),RR,$CLJS.X($CLJS.Gl,
qO),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,qO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(bT)?bT.J:null])):null));return $CLJS.m(a)?a:vR}(),bT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,
$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",56,$CLJS.iD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iD,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:WQ}(),dT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},$CLJS.K(fia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bQ,"metabase/mbql/schema.cljc",55,$CLJS.GJ,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GJ,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:bQ}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(Yha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),
$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",54,$CLJS.jI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jI,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(rT)?
rT.J:null])):null));return $CLJS.m(a)?a:AO}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(sia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",55,$CLJS.VJ,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VJ,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:UP}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&
"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(mka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",53,$CLJS.tI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.tI,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:sQ}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(Vja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,
$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$R,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",69,$CLJS.mI,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mI,$CLJS.IG,$CLJS.X($CLJS.Gl,rR),NR,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(uT)?uT.J:null])):null));return $CLJS.m(a)?a:JR}(),uT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Oia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$R,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AP,"metabase/mbql/schema.cljc",68,$CLJS.oJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oJ,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:AP}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(Xja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,
$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$R,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",67,$CLJS.ZH,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZH,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(wT)?wT.J:null])):
null));return $CLJS.m(a)?a:WP}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},$CLJS.K(Rha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$R,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",67,$CLJS.RK,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,$CLJS.IG,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(xT)?xT.J:null])):null));return $CLJS.m(a)?a:eS}(),xT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==
typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(uO,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Gl,hP),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,AQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:iQ}(),sU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Uha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,
dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.hJ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",65,$CLJS.hJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.hJ,lia,$CLJS.X($CLJS.Gl,oS),mia,$CLJS.X($CLJS.Gl,oS),$CLJS.Wj,$CLJS.X($CLJS.Gl,Qja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(zT)?zT.J:null])):null));return $CLJS.m(a)?a:tQ}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(Dja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",71,$CLJS.oK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.oK,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS),$CLJS.Wj,$CLJS.X($CLJS.Gl,Zia),$CLJS.ek,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,VR))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:LQ}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(Gia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",71,$CLJS.EI,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EI,vQ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(BT)?BT.J:null])):null));return $CLJS.m(a)?a:NO}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(tia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),
$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",74,$CLJS.SK,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,vQ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:JQ}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",72,$CLJS.YH,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YH,vQ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:YQ}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==
typeof lO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(dka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",71,$CLJS.cK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.cK,vQ,$CLJS.X($CLJS.Gl,oS),$CLJS.ek,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,VR))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:XQ}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(Hha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,
$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",70,$CLJS.aK,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aK,vQ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(FT)?FT.J:
null])):null));return $CLJS.m(a)?a:SQ}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",78,$CLJS.jK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jK,vQ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(GT)?GT.J:null])):null));return $CLJS.m(a)?a:OQ}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&
"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",71,$CLJS.wK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.wK,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:rO}(),HT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Ija,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,
HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",73,$CLJS.gK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gK,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:iS}(),IT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(Sia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kQ,"metabase/mbql/schema.cljc",73,$CLJS.DI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DI,$CLJS.BJ,$CLJS.X($CLJS.Gl,oS)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:kQ}(),JT],null)]));
QT=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof cT?new $CLJS.ud(function(){return cT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bR,"metabase/mbql/schema.cljc",59,$CLJS.aI,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.aI,$CLJS.FD,$CLJS.X($CLJS.Gl,xR),$CLJS.SA,$CLJS.X($CLJS.Gl,oia),$CLJS.iD,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,rR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(cT)?cT.J:null])):null));return $CLJS.m(a)?a:bR}(),cT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},
$CLJS.K($ja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",54,$CLJS.BK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BK,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:sR}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},$CLJS.K(Yia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",55,$CLJS.GK,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GK,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:fR}(),gT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&
"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",
55,$CLJS.YK,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YK,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:yQ}(),fT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(Bha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,
$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TR,"metabase/mbql/schema.cljc",57,$CLJS.VK,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.VK,$CLJS.FD,$CLJS.X($CLJS.Gl,xR),Pha,$CLJS.X($CLJS.Gl,$CLJS.QP),$CLJS.AF,$CLJS.X($CLJS.Gl,$CLJS.QP)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(jT)?jT.J:null])):null));return $CLJS.m(a)?a:TR}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(Aja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rS,"metabase/mbql/schema.cljc",55,$CLJS.$u,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$u,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(iT)?iT.J:null])):null));return $CLJS.m(a)?a:rS}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==
typeof lO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},$CLJS.K(cka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FR,"metabase/mbql/schema.cljc",55,$CLJS.Qu,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Qu,$CLJS.FD,$CLJS.X($CLJS.Gl,xR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:FR}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(oja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,
HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",56,$CLJS.oI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.oI,SR,$CLJS.X($CLJS.Gl,xR),RR,$CLJS.X($CLJS.Gl,xR),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,xR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(kT)?kT.J:null])):
null));return $CLJS.m(a)?a:sP}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.PQ,"null",$CLJS.mfa,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",74,$CLJS.OJ,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.OJ,$CLJS.FD,$CLJS.X($CLJS.Gl,xR),$CLJS.zF,$CLJS.X($CLJS.Gl,$CLJS.QP)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:LO}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&
"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof bT?new $CLJS.ud(function(){return bT},$CLJS.K(gR,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.PQ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",
58,$CLJS.QJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QJ,SR,$CLJS.X($CLJS.Gl,qO),RR,$CLJS.X($CLJS.Gl,qO),$O,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uk,qO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(bT)?bT.J:null])):null));return $CLJS.m(a)?a:vR}(),bT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(uO,new $CLJS.k(null,
1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Gl,hP),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,
AQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:iQ}(),sU],null)]));$CLJS.tU=WN.l($CLJS.G([$CLJS.bg(eO,QS),US,$CLJS.bg(eO,NS),OS,$CLJS.bg(eO,RS),ZS,$CLJS.bg(eO,TS),$CLJS.XS,$CLJS.bg(eO,$CLJS.ZI),sU,$CLJS.Su,$CLJS.LS]));uU=dO($CLJS.kD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.LS],null)]));vU=dO($CLJS.MJ,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.LS],null)]));
wU=dO($CLJS.CJ,$CLJS.G(["field-or-expression",$CLJS.tU]));xU=dO($CLJS.QK,$CLJS.G(["field-or-expression",$CLJS.tU]));yU=dO($CLJS.MI,$CLJS.G(["field-or-expression",$CLJS.tU]));zU=dO($CLJS.sK,$CLJS.G(["field-or-expression",$CLJS.tU]));AU=dO($CLJS.rk,$CLJS.G(["field-or-expression",$CLJS.tU]));BU=dO($CLJS.$l,$CLJS.G(["field-or-expression",$CLJS.tU]));CU=dO($CLJS.NK,$CLJS.G(["field-or-expression",$CLJS.tU,"pred",$CLJS.RT]));DU=dO($CLJS.eK,$CLJS.G(["pred",$CLJS.RT]));EU=dO($CLJS.YJ,$CLJS.G(["pred",$CLJS.RT]));
FU=dO($CLJS.zK,$CLJS.G(["field-or-expression",$CLJS.tU]));GU=dO($CLJS.QI,$CLJS.G(["field-or-expression",$CLJS.tU]));HU=dO($CLJS.uK,$CLJS.G(["field-or-expression",$CLJS.tU]));IU=dO($CLJS.mJ,$CLJS.G(["field-or-expression",$CLJS.tU,"percentile",WS]));JU=dO($CLJS.aQ,$CLJS.G(["metric-id",$CLJS.JN($CLJS.G([$CLJS.NN,$CLJS.KN]))]));
KU=XN($CLJS.bg(eO,QS),US,fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof wU?new $CLJS.ud(function(){return wU},$CLJS.K(uja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",60,$CLJS.CJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CJ,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:fQ}(),wU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof xU?
new $CLJS.ud(function(){return xU},$CLJS.K(Aia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",64,$CLJS.QK,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,
GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:MR}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],
[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",65,$CLJS.MI,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MI,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(yU)?yU.J:null])):null));return $CLJS.m(a)?a:jQ}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.kK,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tO,"metabase/mbql/schema.cljc",76,$CLJS.zK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zK,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(FU)?FU.J:null])):null));return $CLJS.m(a)?a:tO}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(ija,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,
$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",60,$CLJS.sK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sK,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(zU)?zU.J:null])):
null));return $CLJS.m(a)?a:DO}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(Lha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.sl,"metabase/mbql/schema.cljc",60,$CLJS.rk,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rk,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(AU)?AU.J:null])):null));return $CLJS.m(a)?a:$CLJS.sl}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&
"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(Gha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.dm,"metabase/mbql/schema.cljc",60,$CLJS.$l,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.$l,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(BU)?BU.J:null])):null));return $CLJS.m(a)?a:$CLJS.dm}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof JU?new $CLJS.ud(function(){return JU},$CLJS.K(nia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,
$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",26,$CLJS.aQ,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aQ,Bia,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uQ,ZO,OR))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(JU)?JU.J:null])):null));return $CLJS.m(a)?a:rP}(),JU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==
typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
lP,"metabase/mbql/schema.cljc",62,$CLJS.YJ,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YJ,$CLJS.zl,$CLJS.X($CLJS.Gl,XP)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:lP}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K(Nha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,
$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",68,$CLJS.eK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eK,$CLJS.zl,$CLJS.X($CLJS.Gl,XP)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(DU)?
DU.J:null])):null));return $CLJS.m(a)?a:BO}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(kka,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",66,$CLJS.NK,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.NK,GR,$CLJS.X($CLJS.Gl,zR),$CLJS.zl,$CLJS.X($CLJS.Gl,XP)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:gQ}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&
"undefined"!==typeof lO&&"undefined"!==typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(uO,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Gl,hP),$CLJS.Ok,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,AQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:iQ}(),sU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(Lja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,
$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LR,"metabase/mbql/schema.cljc",68,$CLJS.uK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uK,GR,$CLJS.X($CLJS.Gl,zR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(HU)?HU.J:null])):
null));return $CLJS.m(a)?a:LR}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",72,$CLJS.mJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mJ,GR,$CLJS.X($CLJS.Gl,zR),$CLJS.mJ,$CLJS.X($CLJS.Gl,rR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(IU)?IU.J:null])):null));return $CLJS.m(a)?a:pR}(),IU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&
"undefined"!==typeof lO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",77,$CLJS.QI,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QI,GR,$CLJS.X($CLJS.Gl,
zR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:sO}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},$CLJS.K(Iha,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",74,$CLJS.MJ,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,XO))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:YP}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(pia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([cQ,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HR,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.ck,"metabase/mbql/schema.cljc",70,$CLJS.kD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kD,$CLJS.EQ,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.yN,XO))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ck}(),uU],null)])));
LU=ZN(new $CLJS.ud(function(){return KU},bka,$CLJS.Sh([$CLJS.qm,$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[!0,$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eka,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(KU)?KU.J:null])));Kka=$CLJS.Pf([SN($CLJS.V),$CLJS.KN,SN($CLJS.TI),$CLJS.KN,$CLJS.GN,$CLJS.BN]);
$CLJS.MU=dO($CLJS.oO,$CLJS.G(["aggregation",LU,"options",Kka]));$CLJS.VS=XN($CLJS.bg(eO,$CLJS.oO),$CLJS.MU,LU);NU=dO($CLJS.FJ,$CLJS.G(["field",$CLJS.MS]));OU=dO($CLJS.sJ,$CLJS.G(["field",$CLJS.MS]));
$CLJS.PU=fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof NU?new $CLJS.ud(function(){return NU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JP,"metabase/mbql/schema.cljc",15,$CLJS.FJ,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,mP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(NU)?NU.J:null])):null));return $CLJS.m(a)?a:JP}(),NU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof OU?new $CLJS.ud(function(){return OU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),
$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",16,$CLJS.sJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sJ,$CLJS.EQ,$CLJS.X($CLJS.Gl,mP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(OU)?OU.J:null])):null));return $CLJS.m(a)?a:hR}(),OU],null)]));
UN.l($CLJS.G([$CLJS.lI,$CLJS.PI,$CLJS.cI,$CLJS.lz,$CLJS.GG,vQ]));QU=$CLJS.Pf([SN($CLJS.Hk),$CLJS.KN,$CLJS.V,$CLJS.KN,$CLJS.TI,$CLJS.KN,$CLJS.GN,$CLJS.BN]);Lka=$CLJS.hn.l($CLJS.G([QU,$CLJS.Pf([$CLJS.Xk,$CLJS.RM($CLJS.lI),$CLJS.eI,$CLJS.KN,lja,$CLJS.NN,SN($CLJS.MQ),$CLJS.NN])]));Mka=$CLJS.hn.l($CLJS.G([QU,new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.RM($CLJS.PI),$CLJS.IK,$CLJS.NN],null)]));RU=$CLJS.hn.l($CLJS.G([QU,$CLJS.Pf([SN($CLJS.Pj),$CLJS.BN,SN(jia),$CLJS.DN])]));
Nka=$CLJS.hn.l($CLJS.G([RU,$CLJS.Pf([$CLJS.Xk,$CLJS.RM($CLJS.cI),$CLJS.cI,JS,$CLJS.cJ,ZN(new $CLJS.ud(function(){return SU},aka,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(SU)?SU.J:null]))),SN($CLJS.Ok),$CLJS.Pf([$CLJS.GN,
$CLJS.BN])])]));Oka=$CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,4,[vQ,null,$CLJS.lz,null,$CLJS.lk,null,$CLJS.GG,null],null),null));Pka=$CLJS.hn.l($CLJS.G([RU,new $CLJS.k(null,1,[$CLJS.Xk,Oka],null)]));Qka=WN.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.cI)},Nka,function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.lI)},Lka,function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.PI)},Mka,$CLJS.Su,Pka]));
Rka=$CLJS.XM($CLJS.Pf([$CLJS.KN,Qka]),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");TU=$CLJS.Pf([$CLJS.vO,$CLJS.BN,SN($CLJS.iR),Rka,SN(nP),$CLJS.aO($CLJS.KN),$CLJS.GN,$CLJS.BN]);
VU=XN($CLJS.Om.h($CLJS.oe,$CLJS.UQ),$CLJS.QN(TU,new $CLJS.k(null,1,[$CLJS.vO,$CLJS.UQ],null)),ZN(new $CLJS.ud(function(){return $CLJS.UU},Kia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.UU)?$CLJS.UU.J:null]))));
WU=$CLJS.Pf([$CLJS.V,$CLJS.KN,$CLJS.tJ,$CLJS.LN,kja,$CLJS.KN,SN($CLJS.CP),$CLJS.aO($CLJS.MN),SN($CLJS.eP),$CLJS.aO($CLJS.wha),$CLJS.BN,$CLJS.BN]);$CLJS.XU=/^card__[1-9]\d*$/;YU=$CLJS.JN($CLJS.G([$CLJS.NN,$CLJS.XU]));Ska=$CLJS.R.h(UN,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.IR,null,$CLJS.mO,null,$CLJS.xQ,null,$CLJS.nR,null],null),null));
Tka=$CLJS.XM($CLJS.Sh([$CLJS.GN,SN($CLJS.OP),SN($CLJS.ZP),SN($CLJS.wP),SN($CLJS.xO),$CLJS.aS,SN($CLJS.lR),SN($CLJS.jR),SN($CLJS.WK)],[$CLJS.BN,$CLJS.aO(new $CLJS.S(null,1,5,$CLJS.T,[WU],null)),YU,$CLJS.KN,$CLJS.VM($CLJS.JN($CLJS.G([UN.l($CLJS.G([$CLJS.QD,$CLJS.vF])),ZN(new $CLJS.ud(function(){return ZU},Gja,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,dR,$CLJS.wm,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qja,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m(ZU)?ZU.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.RT,VU,$CLJS.aO($CLJS.NN),Ska]),$CLJS.Om.h($CLJS.Pm.h($CLJS.ZP,$CLJS.lR),$CLJS.$f($CLJS.Om.h($CLJS.ZP,$CLJS.lR))),"Joins must have either a `source-table` or `source-query`, but not both.");
Uka=$CLJS.XM(gO(new $CLJS.S(null,1,5,$CLJS.T,[Tka],null)),function(a){return hO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.wP,a)))},"All join aliases must be unique.");ZU=$CLJS.VM($CLJS.XM(gO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.LS],null)),hO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.UU=$CLJS.XM($CLJS.XM($CLJS.Sh([$CLJS.GN,SN($CLJS.ZR),SN($CLJS.OP),SN($CLJS.VO),SN($CLJS.ZP),SN($CLJS.JO),SN($CLJS.xO),SN($CLJS.NP),SN($CLJS.GP),SN($CLJS.lR),SN($CLJS.sS),SN(VQ),SN($CLJS.PQ)],[$CLJS.BN,$CLJS.XM(gO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.PU],null)),hO,"distinct"),$CLJS.aO(new $CLJS.S(null,1,5,$CLJS.T,[WU],null)),Uka,YU,$CLJS.RT,ZU,gO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.VS],null)),gO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.LS],null)),VU,$CLJS.ON,new $CLJS.k(null,2,[VQ,$CLJS.NN,cia,$CLJS.NN],
null),$CLJS.Pf([$CLJS.KN,$CLJS.tU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lR,$CLJS.ZP],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.GP);b=$CLJS.M.h(b,$CLJS.xO);return $CLJS.je($CLJS.Ez.h($CLJS.si(a),$CLJS.si(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
$U=$CLJS.Sh([$CLJS.ER,vQ,kP,dP,PP,mS,zP,$CLJS.lS,$CLJS.lz,yP,wO,wR,bS,$CLJS.FO,YR,jP,$CLJS.VP,xP,$CLJS.Hk,$CLJS.vP,pO,$CLJS.SO,$CLJS.uP,rQ,$CLJS.lk,$CLJS.GG,pP],[new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.ER,null,vQ,null,$CLJS.lz,null,$CLJS.lk,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.ER,null,vQ,null,$CLJS.lS,null,$CLJS.VP,null,$CLJS.Hk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,
$CLJS.PJ,DR,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[kP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,pS,$CLJS.PJ,DR,IP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.ER,null,dP,null,$CLJS.lz,null,$CLJS.Hk,null,pO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,$CLJS.PJ,zO,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[PP,null],null),null)],null),new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,1,[mS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,pS,$CLJS.PJ,Eja,IP,new $CLJS.ni(null,
new $CLJS.k(null,1,[zP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.lS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,pS,IP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.ER,null,dP,null,$CLJS.lz,null,$CLJS.Hk,null,pO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,pS,$CLJS.PJ,zO,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[yP,null],null),null)],null),new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,1,[wO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,$CLJS.PJ,DR,IP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.ER,null,mS,null,wO,null,wR,null,xP,null,$CLJS.Hk,null,pO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,$CLJS.PJ,zO,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[bS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.lS,null,$CLJS.FO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,$CLJS.PJ,zO,IP,new $CLJS.ni(null,
new $CLJS.k(null,1,[YR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Pl,$CLJS.PJ,zO,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[jP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.ER,null,vQ,null,$CLJS.lS,null,$CLJS.VP,null,$CLJS.Hk,null],null),null)],null),new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,1,[xP,null],null),null)],null),new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Hk,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.lS,null,$CLJS.vP,null],null),null)],null),new $CLJS.k(null,1,[IP,new $CLJS.ni(null,new $CLJS.k(null,1,[pO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.lS,null,$CLJS.SO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,vQ,IP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.lS,null,$CLJS.uP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,pS,$CLJS.PJ,DR,
IP,new $CLJS.ni(null,new $CLJS.k(null,1,[rQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.lk,IP,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.ER,null,$CLJS.Hk,null,$CLJS.lk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.Pl,IP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.ER,null,mS,null,wO,null,wR,null,xP,null,$CLJS.Hk,null,pO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,pS,$CLJS.PJ,zO,IP,new $CLJS.ni(null,new $CLJS.k(null,1,[pP,null],null),null)],null)]);
Vka=$CLJS.R.h(UN,$CLJS.gi($U));SU=$CLJS.R.h(UN,$CLJS.nf($CLJS.vF,$CLJS.gi($U)));aV=dO(Tha,$CLJS.G(["tag-name",$CLJS.JN($CLJS.G([$CLJS.KN,new $CLJS.k(null,1,[$CLJS.Hk,$CLJS.KN],null)]))]));bV=dO($CLJS.cI,$CLJS.G(["target",$CLJS.JN($CLJS.G([$CLJS.LS,aV]))]));cV=dO(tP,$CLJS.G(["target",aV]));
Wka=$CLJS.JN($CLJS.G([$CLJS.LS,fO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof bV?new $CLJS.ud(function(){return bV},$CLJS.K(aja,new $CLJS.k(null,1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZQ,"metabase/mbql/schema.cljc",21,$CLJS.cI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cI,$CLJS.$K,$CLJS.X($CLJS.Gl,$CLJS.X($CLJS.uQ,XO,HP))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(bV)?bV.J:null])):null));return $CLJS.m(a)?a:ZQ}(),bV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=dQ.g($CLJS.fe("undefined"!==typeof $CLJS.jO&&"undefined"!==typeof kO&&"undefined"!==typeof lO&&"undefined"!==typeof cV?new $CLJS.ud(function(){return cV},$CLJS.K(rja,new $CLJS.k(null,
1,[$CLJS.Rl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.gm,$CLJS.ak,dQ,$CLJS.Bk,$CLJS.Yk,$CLJS.wm,HO,$CLJS.Zj,$CLJS.W,$CLJS.fm],[$CLJS.K(qR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qS,"metabase/mbql/schema.cljc",20,tP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[tP,$CLJS.$K,$CLJS.X($CLJS.Gl,HP)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(cV)?cV.J:null])):null));return $CLJS.m(a)?a:qS}(),
cV],null)]))]));Xka=$CLJS.Pf([$CLJS.Xk,Vka,SN($CLJS.Hk),$CLJS.KN,SN($CLJS.$K),Wka,SN($CLJS.rl),$CLJS.BN,SN($CLJS.V),$CLJS.KN,SN($ha),$CLJS.KN,SN($CLJS.Pj),$CLJS.BN,$CLJS.GN,$CLJS.BN]);Yka=new $CLJS.S(null,1,5,$CLJS.T,[Xka],null);Zka=$CLJS.Pf([SN(ika),$CLJS.KN,$CLJS.GN,$CLJS.BN]);$ka=$CLJS.XM($CLJS.Pf([SN(hQ),$CLJS.ON,SN(CO),$CLJS.ON,$CLJS.GN,$CLJS.BN]),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,hQ);b=$CLJS.M.h(b,CO);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
ala=$CLJS.Pf([SN(Nja),$CLJS.DN,SN(Vha),$CLJS.DN,SN(Cha),$CLJS.DN,SN(bia),$CLJS.DN,SN(Fja),$CLJS.aO($CLJS.DN),SN(Fha),$CLJS.aO($CLJS.DN),SN(Uja),$CLJS.aO($CLJS.DN),$CLJS.GN,$CLJS.BN]);bla=UN.l($CLJS.G([nja,nP,bja,sja,Nia,Qha,Wha,iia,yia,Bja,ska,Cia,uia]));
cla=$CLJS.Sh([$CLJS.GN,SN(jka),SN(Eia),SN($CLJS.oP),SN(Kja),SN(Lia),SN(Dha),SN(gka),SN($CLJS.IK),SN($CLJS.MO)],[$CLJS.BN,$CLJS.aO($CLJS.KN),$CLJS.aO($CLJS.NN),$CLJS.aO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pf([$CLJS.BN,$CLJS.BN])],null)),$CLJS.aO($CLJS.NN),$CLJS.aO($CLJS.NN),$CLJS.aO($CLJS.BN),$CLJS.aO($CLJS.Pf([$CLJS.BN,$CLJS.BN])),$CLJS.aO($CLJS.NN),$CLJS.aO(bla)]);dla=$CLJS.JN($CLJS.G([$CLJS.RM(-1337),$CLJS.NN]));
$CLJS.dV=$CLJS.XM($CLJS.XM($CLJS.XM($CLJS.Sh([$CLJS.GN,SN($CLJS.UQ),SN($CLJS.mR),SN($CLJS.ZD),SN($ia),SN(Pia),$CLJS.Xk,SN($CLJS.vO),$CLJS.MQ,SN($CLJS.OO)],[$CLJS.BN,TU,$CLJS.aO(Zka),$CLJS.aO(cla),$CLJS.aO($ka),$CLJS.aO(ala),UN.l($CLJS.G([$CLJS.vO,$CLJS.UQ])),$CLJS.UU,dla,Yka]),$CLJS.Om.h($CLJS.Pm.h($CLJS.UQ,$CLJS.vO),$CLJS.$f($CLJS.Om.h($CLJS.UQ,$CLJS.vO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.UQ);var c=$CLJS.M.h(b,$CLJS.vO);
b=$CLJS.M.h(b,$CLJS.Xk);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.$f($CLJS.OP),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.bO(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.zj($CLJS.VL("Value does not match schema: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.Xk,$CLJS.eha,$CLJS.em,a,$CLJS.rl,c,$CLJS.Vu,d],null));return c}})($CLJS.dV);