var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Dla,Ela,Fla,Gla,Hla,Ila,Jla,rW,Kla,Lla,Mla,Nla,Ola,Pla,Qla,Rla,tW,Sla;$CLJS.lW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);Dla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.mW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Ela=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Fla=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.nW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.oW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.pW=new $CLJS.N(null,"column-name","column-name",551523580);Gla=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.qW=new $CLJS.N(null,"display-info","display-info",-816930907);
Hla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);Ila=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Jla=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);rW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Kla=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Lla=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
Mla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Nla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);Ola=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Pla=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Qla=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.sW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Rla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
tW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);Sla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.zV($CLJS.kD,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.KL],null)],null)],null)],null)]));$CLJS.zV($CLJS.MJ,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.KL],null)],null)],null)],null)]));
$CLJS.xV($CLJS.CJ,$CLJS.G([$CLJS.wu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));$CLJS.xV($CLJS.MI,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.KL],null)],null)]));$CLJS.xV($CLJS.eK,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.JL],null)],null)]));
$CLJS.xV($CLJS.$l,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PL],null)],null)]));$CLJS.mV($CLJS.$l,$CLJS.OL);$CLJS.xV($CLJS.uK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));$CLJS.mV($CLJS.uK,$CLJS.OL);$CLJS.xV($CLJS.rk,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.PL],null)],null)]));$CLJS.mV($CLJS.rk,$CLJS.OL);
$CLJS.jL(rW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.dv,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.dv,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.xV($CLJS.mJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,rW],null)]));$CLJS.mV($CLJS.mJ,$CLJS.OL);
$CLJS.xV($CLJS.YJ,$CLJS.G([$CLJS.wu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.JL],null)],null)]));$CLJS.xV($CLJS.zK,$CLJS.G([$CLJS.wu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));$CLJS.xV($CLJS.sK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));
$CLJS.xV($CLJS.QK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));$CLJS.mV($CLJS.sK,$CLJS.OL);$CLJS.mV($CLJS.QK,$CLJS.OL);$CLJS.xV($CLJS.NK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.JL],null)],null)]));$CLJS.mV($CLJS.NK,$CLJS.OL);
$CLJS.xV($CLJS.QI,$CLJS.G([$CLJS.wu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.RL],null)],null)]));$CLJS.jL(tW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Ck,Rla,Pla,Ila,Ela,Dla,Hla,Jla,Kla,Lla,Ola,Mla,Sla,Qla,Fla,Nla,$CLJS.Lb],null));$CLJS.jL($CLJS.mW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nl,new $CLJS.k(null,1,[$CLJS.rk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,tW],null)],null));
$CLJS.uW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.BV,$CLJS.kD,$CLJS.oW,!1,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Count of rows"),$CLJS.pW,$CLJS.VH("Count"),$CLJS.WG,$CLJS.VH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.sK,$CLJS.lW,$CLJS.Xl,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Sum of ..."),$CLJS.pW,$CLJS.VH("Sum"),$CLJS.WG,$CLJS.VH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.CJ,$CLJS.lW,$CLJS.Xl,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Average of ..."),$CLJS.pW,$CLJS.VH("Average"),$CLJS.WG,$CLJS.VH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.uK,$CLJS.lW,$CLJS.Xl,$CLJS.oW,!0,$CLJS.sW,$CLJS.EJ,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Median of ..."),$CLJS.pW,$CLJS.VH("Median"),$CLJS.WG,$CLJS.VH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.MI,$CLJS.lW,$CLJS.Ut,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Number of distinct values of ..."),$CLJS.pW,$CLJS.VH("Distinct values"),$CLJS.WG,$CLJS.VH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.QK,$CLJS.lW,$CLJS.Xl,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Cumulative sum of ..."),
$CLJS.pW,$CLJS.VH("Sum"),$CLJS.WG,$CLJS.VH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.BV,$CLJS.MJ,$CLJS.oW,!1,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Cumulative count of rows"),$CLJS.pW,$CLJS.VH("Count"),$CLJS.WG,$CLJS.VH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.zK,$CLJS.lW,$CLJS.Xl,$CLJS.oW,
!0,$CLJS.sW,$CLJS.kK,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Standard deviation of ..."),$CLJS.pW,$CLJS.VH("SD"),$CLJS.WG,$CLJS.VH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.rk,$CLJS.lW,$CLJS.Gk,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Minimum of ..."),$CLJS.pW,$CLJS.VH("Min"),$CLJS.WG,$CLJS.VH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.BV,$CLJS.$l,$CLJS.lW,$CLJS.Gk,$CLJS.oW,!0,$CLJS.sW,$CLJS.fI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Maximum of ..."),$CLJS.pW,$CLJS.VH("Max"),$CLJS.WG,$CLJS.VH("Maximum value of a column")],null)}],null)],null);
$CLJS.jL(Gla,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.nW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BV,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ht],null),$CLJS.rg.g($CLJS.BV),$CLJS.uW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.Fk],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oW,$CLJS.lk],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.sW,$CLJS.Fk],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qW,$CLJS.de],null)],null));