var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var EX,FX,GX,IX,JX,KX,LX;EX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);FX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
GX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.HX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);IX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
JX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);KX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);LX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.xV($CLJS.iJ,$CLJS.G([$CLJS.wu,$CLJS.lJ,$CLJS.Rt,$CLJS.nL]));$CLJS.BL.o(null,$CLJS.HX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.CL(a);return $CLJS.le(a)?(a=$CLJS.Ez.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.SJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.mV($CLJS.HX,$CLJS.OL);
for(var MX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dK,$CLJS.LK],null)),NX=null,OX=0,PX=0;;)if(PX<OX){var QX=NX.$(null,PX);$CLJS.xV(QX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.nL],null)]));$CLJS.mV(QX,$CLJS.HX);PX+=1}else{var RX=$CLJS.A(MX);if(RX){var SX=RX;if($CLJS.re(SX)){var TX=$CLJS.$c(SX),Ima=$CLJS.ad(SX),Jma=TX,Kma=$CLJS.E(TX);MX=Ima;NX=Jma;OX=Kma}else{var UX=$CLJS.C(SX);$CLJS.xV(UX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.jq,$CLJS.IL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.nL],null)]));$CLJS.mV(UX,$CLJS.HX);MX=$CLJS.D(SX);NX=null;OX=0}PX=0}else break}
for(var VX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.EI,$CLJS.YH,$CLJS.aK,$CLJS.wK,$CLJS.gK,$CLJS.DI,$CLJS.SK],null)),WX=null,XX=0,YX=0;;)if(YX<XX){var Lma=WX.$(null,YX);$CLJS.xV(Lma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)]));YX+=1}else{var ZX=$CLJS.A(VX);if(ZX){var $X=ZX;if($CLJS.re($X)){var aY=$CLJS.$c($X),Mma=$CLJS.ad($X),Nma=aY,Oma=$CLJS.E(aY);VX=Mma;WX=Nma;XX=Oma}else{var Pma=$CLJS.C($X);$CLJS.xV(Pma,$CLJS.G([$CLJS.wu,
$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)]));VX=$CLJS.D($X);WX=null;XX=0}YX=0}else break}$CLJS.xV($CLJS.hJ,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.EV],null)]));
for(var bY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.SK,null,$CLJS.gK,null,$CLJS.wK,null,$CLJS.jK,null,$CLJS.DI,null,$CLJS.YH,null,$CLJS.EI,null,$CLJS.aK,null],null),null)),cY=null,dY=0,eY=0;;)if(eY<dY){var Qma=cY.$(null,eY);$CLJS.xV(Qma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)]));eY+=1}else{var fY=$CLJS.A(bY);if(fY){var gY=fY;if($CLJS.re(gY)){var hY=$CLJS.$c(gY),Rma=$CLJS.ad(gY),Sma=hY,Tma=$CLJS.E(hY);
bY=Rma;cY=Sma;dY=Tma}else{var Uma=$CLJS.C(gY);$CLJS.xV(Uma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)]));bY=$CLJS.D(gY);cY=null;dY=0}eY=0}else break}$CLJS.jL(LX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ht,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.zV($CLJS.cK,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,LX],null)],null)],null)],null)]));
$CLJS.jL(KX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.tL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"valid timezone ID",$CLJS.Wu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.UL.tz.names()))],null));
$CLJS.zV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$K,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,KX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,KX],null)],null)],null)],null)]));
$CLJS.mV($CLJS.uI,$CLJS.HX);$CLJS.xV($CLJS.DJ,$CLJS.G([$CLJS.wu,$CLJS.WI]));$CLJS.jL(GX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.OH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OI,$CLJS.SJ],null))}],null)],null));
$CLJS.jL(EX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,GX],null)],null)],null)],null));
$CLJS.tV($CLJS.FI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.dv,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.FI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,EX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.uX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.wX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.pX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.GV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.mB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.yX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.DV],null)],null)],null)],null)],null)],null));
$CLJS.BL.o(null,$CLJS.FI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.mB)?$CLJS.F.h(a,$CLJS.Pj)?$CLJS.SJ:$CLJS.m($CLJS.oV($CLJS.GV,a))?$CLJS.OI:$CLJS.SJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.CX,b))?$CLJS.OI:$CLJS.m($CLJS.Di($CLJS.DX,b))?$CLJS.OI:null:null;if($CLJS.m(a))return a;b=$CLJS.CL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.OH(c,$CLJS.Ml)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.jL(JX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.mB],null),$CLJS.Rt],null));
$CLJS.zV($CLJS.rJ,$CLJS.G([$CLJS.wu,$CLJS.SJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,JX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.nL],null)],null)],null)],null)]));
$CLJS.xV($CLJS.YD,$CLJS.G([$CLJS.wu,$CLJS.XK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.FV],null)]));$CLJS.jL(FX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Ht,$CLJS.fJ,$CLJS.hk,$CLJS.cm,$CLJS.XI,$CLJS.UK,$CLJS.ZK,$CLJS.vl,$CLJS.nk,$CLJS.bk,$CLJS.Sk,$CLJS.II],null));$CLJS.jL(IX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ht,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.zV($CLJS.oK,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,FX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jq,IX],null)],null)],
null)],null)]));