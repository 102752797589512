var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var qL,xga,uL,zL;
$CLJS.oL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Gu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.pL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.fo(a));};qL=function(a){return $CLJS.OH(a,$CLJS.dl)||$CLJS.OH(a,$CLJS.Qj)};
$CLJS.rL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);xga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.sL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.tL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
uL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.vL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.wL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.xL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.yL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);zL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.jL($CLJS.tL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.k(null,1,[$CLJS.rk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.dv,"non-blank string"],null),$CLJS.$f($CLJS.OF)],null)],null));$CLJS.jL($CLJS.wL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.rk,0],null)],null));$CLJS.jL($CLJS.rL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.rk,1],null)],null));
$CLJS.jL(zL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.k(null,2,[$CLJS.rk,36,$CLJS.$l,36],null)],null));$CLJS.jL(uL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.dv,"valid semantic type",$CLJS.Wu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),qL],null));
$CLJS.jL($CLJS.yL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.dv,"valid base type",$CLJS.Wu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.OH(a,$CLJS.ol)&&!qL(a)}],null));
$CLJS.jL($CLJS.xL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HG,zL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ll,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,
uL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.tL],null)],null)],null));
$CLJS.jL(xga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.sL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,$CLJS.Pl,$CLJS.Fk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ok,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nl,$CLJS.Ut],null)],null)],null));